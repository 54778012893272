import {
    ColumnLayout,
    Input,
    Button,
    Header,
    Container,
    SpaceBetween,
    ContentLayout,
} from '@amzn/awsui-components-react-v3';
import { get as _get, pick } from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import shortUUID from 'short-uuid';

import {
    Loader,
    AccessCodeTable,
    StudentRosterTable,
    StudentRosterModal,
    RemoveStudentModal,
    GilmoreHealthBanner,
    JamDetailsSection,
    WidgetContainer,
    CancelClass,
    CancelClassConfirmationDialog,
    ManageClassDropdown,
} from '@/components';
import {
    CLASS_ACCESS_TYPES,
    EMAIL_REGEX,
    NEW_LINE_SPACE_REGEX,
    LOCATION_TYPES,
    getInitialConfigFromStudentListEditState,
} from '@/components/classForm/ClassForm.utils';
import { Layout } from '@/containers/Layout';
import { ProviderType } from '@/containers/providerPages/ProvidersPage';
import { useProvider } from '@/data/ProviderContext';
import { useGetAttendanceListingEnabled } from '@/data/useAttendanceManagement';
import { useClassroomData } from '@/data/useClassroomData';
import { useGetLearningActivity } from '@/data/useLearningActivity';
import {
    cancelClassroom,
    getCourseVersionDetails,
    addStudentsToClassroomRoster,
    excludeUser,
    updateClassroom,
    listClassroomStudents,
} from '@/modules';
import {
    getDateTimeData,
    getDuration,
    getStudentURL,
    isEmpty,
    paths,
    toDetailPageTimeFormat,
    useFlags,
    calculateReservedLicenses,
    DATEPICKER_FORMAT,
} from '@/utils';
import { classDetailPageBreadcrumb, classesPageBreadcrumb } from '@/utils/breadcrumbs';

import messages from './ClassDetailPage.messages';

import './ClassDetailPage.scss';

const ClassDetailPage = () => {
    const { formatMessage, locale } = useIntl();
    const history = useHistory();
    const { classroomId: encodedClassroomId } = useParams();
    const classroomId = encodedClassroomId ? decodeURIComponent(encodedClassroomId) : undefined;
    const flags = useFlags();
    const {
        classData,
        updateStudentRoster,
        deleteFromStudentRoster,
        updateClassroomData,
        refetch: classroomRefetch,
    } = useClassroomData(classroomId, {
        withConsistencyRetry: true,
        withInstructors: flags.instructorAssignment,
    });
    const location = useLocation();
    const provider = useProvider();

    const {
        openStudentListModal: openStudentListModalInitialValue,
        providersMap: providersMapInitialValue,
    } = loadInitialStudentListConfig(classroomId, location, history, provider);

    const { data: learningActivity } = useGetLearningActivity(classData);

    const [hasStudentRoster, setHasStudentRoster] = useState(false);
    const [hasAccessCodes, setHasAccessCodes] = useState(false);
    const [courseVersionDetails, courseVersionDetailsSet] = useState({});
    const instructors = classData?.classroom?.instructors || [];
    const [loading, setLoading] = useState(false);
    const [openStudentListModal, setOpenStudentListModal] = useState(
        openStudentListModalInitialValue,
    );
    const [cancelClassDialogVisible, cancelClassDialogVisibleSet] = useState(false);
    const [providersMap, setProvidersMap] = useState(providersMapInitialValue);
    const [studentRosterErrors, setStudentRosterErrors] = useState([]);
    const [studentRosterCapacityExceededErrors, setStudentRosterCapacityExceededErrors] = useState(
        [],
    );
    const [showRemoveStudentModal, setShowRemoveStudentModal] = useState(false);
    const [isRemovingAcceptedStudent, setIsRemovingAcceptedStudent] = useState(false);
    const [studentToRemoveFromRoster, setStudentToRemoveFromRoster] = useState('');
    const [classCapacitySizeExceededBy, setClassCapacitySizeExceededBy] = useState(0);
    const [gilmoreLicenseError, setGilmoreLicenseError] = useState(false);
    const [showBtnSpinner, setShowBtnSpinner] = useState(false);
    const [addToRosterSucceeded, setAddToRosterSucceeded] = useState(false);
    const [shouldResetStudentListInput, setShouldResetStudentListInput] = useState(false);

    const jamTrainings = classData?.jamTrainings?.trainings || [];
    const attendanceMarkingEnabled = useGetAttendanceListingEnabled(learningActivity);

    const subProvidersArnMap = provider.requiresSubProvider
        ? Object.fromEntries(
              provider.subProviderData.map((data) => [data.providerArn, data.providerName]),
          )
        : null;

    const compareStudentUser = (student1, student2) => {
        const eKit1 = student1.eKitLicensedProviderArn;
        const eKit2 = student2.eKitLicensedProviderArn;
        if (eKit1 && eKit2) {
            return subProvidersArnMap[eKit1] < subProvidersArnMap[eKit2]
                ? -1
                : subProvidersArnMap[eKit1] === subProvidersArnMap[eKit2]
                  ? 0
                  : 1;
        }
        if (!eKit1) return !eKit2 ? 0 : -1;
        return 1;
    };

    useEffect(() => {
        if (provider.requiresSubProvider && classData.classroom?.studentRoster) {
            classData.classroom.studentRoster.sort(compareStudentUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classData.classroom?.studentRoster]);

    useEffect(() => {
        const courseArn = _get(classData, 'course.courseId', '');
        if (courseArn) {
            getCourseVersionDetails(courseArn, classData.classroom.providerArn).then(
                courseVersionDetailsSet,
            );
        }
    }, [classData]);

    useEffect(() => {
        if (classData.classroom) {
            if (
                flags.studentRoster &&
                classData.classroom.accessType === CLASS_ACCESS_TYPES.roster
            ) {
                setHasStudentRoster(true);
            } else {
                setHasStudentRoster(false);
                setHasAccessCodes(true);
            }
        }
    }, [flags.studentRoster, classData.classroom, hasAccessCodes]);

    useEffect(() => {
        setLoading(classData.classroom && classroomId === classData.classroom.classroomId);
    }, [classroomId, classData.classroom]);

    const {
        virtualUrl,
        locationType,
        timezone = moment.tz.guess(),
        physicalAddress,
    } = _get(classData, 'classroom.locationData', {});
    const { addressLine1, addressLine2, city, state, postalCode, country } = physicalAddress || {};

    const startsOn = _get(classData, 'classroom.startsOn', 0);
    const endsOn = _get(classData, 'classroom.endsOn', 0);

    const { startDate, endDate } = getDateTimeData({
        startsOn,
        endsOn,
        timezone,
        dateFormat: DATEPICKER_FORMAT,
    });

    const classCapacity = _get(classData, 'classroom.classCapacity', 0);
    const studentCount = _get(classData, 'classroom.studentCount', 0);
    const trainingProviderName = classData?.classroom?.trainingProviderName;
    const trainingProviderType = classData?.classroom?.trainingProviderType;
    const createdBy = _get(classData, 'classroom.createdBy');
    const subProviderCapacity = _get(classData, 'classroom.subProviderCapacity', null);
    const editDisabled = moment().isAfter(moment.unix(endsOn));
    const isGrimsbyClassroom = !!_get(classData, 'classroom.learningActivityID');

    const startTime = toDetailPageTimeFormat(startsOn, timezone);
    const endTime = toDetailPageTimeFormat(endsOn, timezone);
    const timeFormatted = `${startTime} - ${endTime}`;
    const subProviderCapacityMap = subProviderCapacity
        ? Object.fromEntries(
              subProviderCapacity.map((entry) => [
                  entry.subProviderArn,
                  {
                      capacity: entry.capacity,
                      studentCount: entry.subProviderStudentCount,
                  },
              ]),
          )
        : {};

    useEffect(() => {
        if (classData) {
            if (!provider.requiresSubProvider) {
                setProvidersMap(
                    Object.fromEntries([
                        [
                            provider.name,
                            {
                                ...providersMap[provider.name],
                                capacity: classCapacity,
                                studentCount: studentCount,
                            },
                        ],
                    ]),
                );
            } else if (subProviderCapacity) {
                setProvidersMap(
                    Object.fromEntries(
                        Object.keys(providersMap).map((providerName) => {
                            const capacityDataOfThisProvider =
                                subProviderCapacityMap[providersMap[providerName].arn];
                            return [
                                providerName,
                                {
                                    ...providersMap[providerName],
                                    capacity: capacityDataOfThisProvider?.capacity ?? 0,
                                    studentCount: capacityDataOfThisProvider?.studentCount ?? 0,
                                },
                            ];
                        }),
                    ),
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classData, classCapacity, subProviderCapacity]);

    const removeDuplicateUsers = (users) => {
        return [...new Map(users.map((user) => [user.email, user])).values()];
    };

    const validateCSVList = (value) => {
        const listErrors = [];
        const users = [];
        value
            .replace(/[\s\n,]+/g, ' ')
            .trim()
            .split(' ')
            .forEach((email) => {
                if (EMAIL_REGEX.test(email) && !NEW_LINE_SPACE_REGEX.test(email)) {
                    users.push({ email });
                } else {
                    listErrors.push({ email });
                }
            });
        const uniqueUsers = removeDuplicateUsers(users);
        return {
            listErrors,
            users: uniqueUsers,
        };
    };

    const clearAllProviderStudentListInput = () => {
        setProvidersMap(
            Object.fromEntries(
                Object.keys(providersMap).map((providerName) => [
                    providerName,
                    {
                        ...providersMap[providerName],
                        studentSubListValue: '',
                    },
                ]),
            ),
        );
    };

    const closeAndResetModal = () => {
        setOpenStudentListModal(false);
        clearAllProviderStudentListInput();
        setGilmoreLicenseError(false);
        resetErrorBanners();
    };

    const resetErrorBanners = () => {
        setAddToRosterSucceeded(false);
        setStudentRosterErrors([]);
        setStudentRosterCapacityExceededErrors([]);
        setClassCapacitySizeExceededBy(0);
    };

    const addStudentsToRoster = async (selectedProviderName) => {
        setShowBtnSpinner(true);
        setAddToRosterSucceeded(false);
        const studentListValueForThisProvider =
            providersMap[selectedProviderName].studentSubListValue;
        const { listErrors, users } = validateCSVList(studentListValueForThisProvider);
        if (listErrors.length > 0) {
            setStudentRosterErrors(listErrors);
            setShowBtnSpinner(false);
        } else {
            setStudentRosterErrors([]);
            setStudentRosterCapacityExceededErrors([]);
            try {
                let args = {
                    users,
                    classroomId: classData.classroom.classroomId,
                    providerArn: classData.classroom.providerArn,
                };
                if (classData.classroom.requiresSubProvider) {
                    args = {
                        ...args,
                        subProviderArn: providersMap[selectedProviderName].arn,
                    };
                }
                const { addStudentsToClassroomRoster: addStudentsToRosterResponse } =
                    await addStudentsToClassroomRoster(args);
                if (addStudentsToRosterResponse.statusCode === 200) {
                    const { classroomUsers: newUsers } = await listClassroomStudents(
                        classData.classroom.classroomId,
                        classData.classroom.providerArn,
                    );

                    updateStudentRoster(newUsers);
                    setShouldResetStudentListInput(true);
                    setAddToRosterSucceeded(true);
                }
            } catch (error) {
                const { errors } = JSON.parse(error);
                const { errorCodes } = errors[0];
                if (
                    errorCodes[0].code === 'PropertyError:ClassCapacityAndNewRosterMismatch' ||
                    errorCodes[0].code === 'PropertyError:ClassCapacityAndCurrentRosterMismatch'
                ) {
                    let capacity = 0;
                    let curStudentCount = 0;
                    let studentRoster = classData.classroom.studentRoster;
                    if (!provider.requiresSubProvider) {
                        curStudentCount = classData.classroom.studentCount;
                        capacity = classData.classroom.classCapacity;
                    } else {
                        capacity =
                            subProviderCapacityMap[providersMap[selectedProviderName].arn].capacity;
                        curStudentCount =
                            subProviderCapacityMap[providersMap[selectedProviderName].arn]
                                .studentCount;
                        studentRoster = studentRoster.filter(
                            (student) =>
                                student.eKitLicensedProviderArn ===
                                providersMap[selectedProviderName].arn,
                        );
                    }
                    let classSizeExceededBy = 0;
                    const newStudents = users.length;
                    const currentReservedLicenses = calculateReservedLicenses(
                        studentRoster,
                        curStudentCount,
                    );
                    if (newStudents + currentReservedLicenses > capacity) {
                        classSizeExceededBy = newStudents + currentReservedLicenses - capacity;
                    }
                    setClassCapacitySizeExceededBy(classSizeExceededBy);
                    setStudentRosterCapacityExceededErrors([errorCodes[0].code]);
                }
            } finally {
                setShowBtnSpinner(false);
            }
        }
    };

    const capacityUpdateWithRoster = async () => {
        if (classData.classroom.requiresSubProvider) {
            throw new Error('Should not run capacityUpdateWithRoster when it has subProviders.');
        }
        setShowBtnSpinner(true);
        const studentListValueForThisProvider =
            providersMap[trainingProviderName].studentSubListValue;
        const { listErrors, users } = validateCSVList(studentListValueForThisProvider);
        if (listErrors.length > 0) {
            setStudentRosterErrors(listErrors);
            setShowBtnSpinner(false);
        } else {
            const licensesReserved = calculateReservedLicenses(
                classData.classroom.studentRoster,
                classData.classroom.studentCount,
            );
            const newClassCapacity = licensesReserved + users.length;
            const classroom = pick(classData.classroom, [
                'providerArn',
                'langLocale',
                'courseId',
                'startsOn',
                'endsOn',
                'accessType',
            ]);
            const locationData = pick(classData.classroom.locationData, [
                'virtualUrl',
                'timezone',
                'locationType',
            ]);
            try {
                await updateClassroom({
                    classCapacity: newClassCapacity,
                    classroom: {
                        ...classroom,
                        locationData,
                    },
                    classroomId,
                    clientRequestToken: shortUUID.generate(),
                });
                const { addStudentsToClassroomRoster: addStudentsToRosterResponse } =
                    await addStudentsToClassroomRoster({
                        users,
                        classroomId: classData.classroom.classroomId,
                        providerArn: classData.classroom.providerArn,
                    });
                if (addStudentsToRosterResponse.statusCode === 200) {
                    const { classroomUsers: newUsers } = await listClassroomStudents(
                        classData.classroom.classroomId,
                        classData.classroom.providerArn,
                    );
                    updateStudentRoster(newUsers);
                    closeAndResetModal();
                    updateClassroomData({ classCapacity: newClassCapacity });
                }
            } catch (error) {
                const { errors } = JSON.parse(error);
                const { errorCodes } = errors[0];
                if (errorCodes[0].code === 'PropertyError:IncreaseInvalid') {
                    setClassCapacitySizeExceededBy(0);
                    setGilmoreLicenseError(true);
                }
            } finally {
                setShowBtnSpinner(false);
            }
        }
    };

    const removeStudentFromRoster = async (email) => {
        try {
            setShowBtnSpinner(true);
            await excludeUser({
                classroomId: classData.classroom.classroomId,
                providerArn: classData.classroom.providerArn,
                user: { email },
                relationship: 'student',
                clientRequestToken: shortUUID.generate(),
            });
            deleteFromStudentRoster(email);
            setShowRemoveStudentModal(false);
        } catch (error) {
            console.log(error);
        } finally {
            setShowBtnSpinner(false);
        }
    };

    const onRemoveStudent = async (email, hasAcceptedInvitation) => {
        setShowRemoveStudentModal(true);
        setStudentToRemoveFromRoster(email);
        setIsRemovingAcceptedStudent(hasAcceptedInvitation);
    };

    return (
        <Layout
            breadcrumbItems={[
                classesPageBreadcrumb(formatMessage),
                classDetailPageBreadcrumb(formatMessage, classroomId),
            ]}
            useMaxWidth
        >
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <Loader isLoading={isEmpty(loading)}>
                <GilmoreHealthBanner />
                <CancelClassConfirmationDialog
                    isVisible={cancelClassDialogVisible}
                    setIsVisible={cancelClassDialogVisibleSet}
                    onConfirm={() => cancelClassroom(classroomId, classData.classroom?.providerArn)}
                    trainingProviderType={classData.classroom?.trainingProviderType}
                />
                <ContentLayout
                    header={
                        <Header
                            variant='h1'
                            actions={
                                <SpaceBetween direction='horizontal' size='s'>
                                    <Button
                                        variant='normal'
                                        disabled={editDisabled}
                                        ariaLabel={formatMessage(messages.editButtonLabel)}
                                        onClick={() =>
                                            history.push(paths.classEditPage(encodedClassroomId))
                                        }
                                    >
                                        {formatMessage(messages.editButtonText)}
                                    </Button>
                                    {attendanceMarkingEnabled ? (
                                        <Button
                                            variant='normal'
                                            disabled={false}
                                            ariaLabel={formatMessage(
                                                messages.classAttendanceButtonLabel,
                                            )}
                                            onClick={() =>
                                                history.push(
                                                    paths.classAttendance(encodedClassroomId),
                                                )
                                            }
                                        >
                                            {formatMessage(messages.classAttendanceButtonText)}
                                        </Button>
                                    ) : null}
                                    <CancelClass
                                        isCancellable={classData.classroom?.isCancellable}
                                        openConfirmationDialog={cancelClassDialogVisibleSet}
                                    />
                                    <ManageClassDropdown />
                                </SpaceBetween>
                            }
                        >
                            {trainingProviderName ? `${trainingProviderName} - ` : ''}
                            {formatMessage(messages.sectionTitle)}
                        </Header>
                    }
                >
                    <SpaceBetween direction='vertical' size='l'>
                        <Container
                            header={
                                <Header variant='h2'>
                                    {formatMessage(messages.classInformationHeader)}
                                </Header>
                            }
                        >
                            <ColumnLayout columns={4} borders='vertical' variant='text-grid'>
                                <WidgetContainer title={formatMessage(messages.courseTitleLabel)}>
                                    {_get(classData, 'course.title', '')}
                                </WidgetContainer>
                                <WidgetContainer title={formatMessage(messages.courseVersionLabel)}>
                                    {_get(classData, 'course.courseId', '').split(':').pop()}
                                </WidgetContainer>
                                <WidgetContainer
                                    title={formatMessage(messages.courseContentVersionLabel)}
                                >
                                    {_get(courseVersionDetails, 'contentVersion', '-')}
                                </WidgetContainer>
                                <WidgetContainer
                                    title={formatMessage(messages.courseLanguageLabel)}
                                >
                                    {_get(classData, 'course.langLocale', '')}
                                </WidgetContainer>
                                <WidgetContainer title={formatMessage(messages.dateLabel)}>
                                    <span>
                                        {startDate} - {endDate}
                                    </span>
                                </WidgetContainer>
                                <WidgetContainer title={formatMessage(messages.timeLabel)}>
                                    {timeFormatted}
                                </WidgetContainer>
                                <WidgetContainer title={formatMessage(messages.durationLabel)}>
                                    {getDuration(startsOn, endsOn, locale)}
                                </WidgetContainer>
                                <WidgetContainer title={formatMessage(messages.timeZoneLabel)}>
                                    {timezone}
                                </WidgetContainer>
                                <WidgetContainer
                                    title={
                                        trainingProviderType === ProviderType.Direct
                                            ? formatMessage(messages.trainingProviderLabel)
                                            : formatMessage(messages.trainingPartnerLabel)
                                    }
                                >
                                    {trainingProviderName}
                                </WidgetContainer>
                                <WidgetContainer
                                    title={formatMessage(messages.classNumberOfStudents)}
                                >
                                    {classCapacity}
                                </WidgetContainer>
                                {createdBy ? (
                                    <WidgetContainer title={formatMessage(messages.classCreatedBy)}>
                                        {createdBy}
                                    </WidgetContainer>
                                ) : null}
                                <WidgetContainer title={formatMessage(messages.classInstructor)}>
                                    {instructors.length ? instructors : '-'}
                                </WidgetContainer>
                            </ColumnLayout>
                        </Container>
                        <Container
                            header={
                                <Header variant='h2'>
                                    {formatMessage(messages.classLocationHeader)}
                                </Header>
                            }
                        >
                            {locationType === LOCATION_TYPES.virtual ? (
                                <Fragment>
                                    <WidgetContainer
                                        title={
                                            <span id='class-detail__virtual-url-label'>
                                                {formatMessage(messages.virtualClassUrlLabel)}
                                            </span>
                                        }
                                    >
                                        <div className='class-detail__virtual-url-icon-wrapper'>
                                            <Button
                                                variant='icon'
                                                iconName='external'
                                                ariaLabel={formatMessage(
                                                    messages.openVirtualClassUrlLabel,
                                                )}
                                                href={virtualUrl}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            />
                                        </div>

                                        <Input // using input for easier to copy text
                                            label={formatMessage(messages.virtualClassUrlLabel)}
                                            ariaRequired
                                            readOnly
                                            ariaLabelledby='class-detail__virtual-url-label'
                                            value={virtualUrl}
                                        />
                                    </WidgetContainer>
                                </Fragment>
                            ) : null}
                            {locationType === LOCATION_TYPES.physical ? (
                                <WidgetContainer title={formatMessage(messages.classAddressLabel)}>
                                    {addressLine1}
                                    {addressLine2}
                                    <>
                                        {city}, {state} {postalCode}, {country}
                                    </>
                                </WidgetContainer>
                            ) : null}
                        </Container>

                        <AccessCodeTable
                            accessCodes={_get(classData, 'classroom.accessCodes', [])}
                            studentUrl={getStudentURL(classroomId)}
                            hasStudentRoster={hasStudentRoster}
                        />

                        {flags.studentRoster && hasStudentRoster && (
                            <>
                                <Container
                                    disableContentPaddings
                                    header={
                                        <Header
                                            variant='h2'
                                            actions={
                                                <Button
                                                    disabled={isGrimsbyClassroom}
                                                    onClick={() => setOpenStudentListModal(true)}
                                                >
                                                    {formatMessage(messages.addStudentListButton)}
                                                </Button>
                                            }
                                        >
                                            {formatMessage(messages.classStudentRosterHeader)}
                                        </Header>
                                    }
                                    noGutters
                                >
                                    <StudentRosterTable
                                        roster={classData.classroom.studentRoster}
                                        onRemoveStudent={onRemoveStudent}
                                        isGrimsbyClassroom={isGrimsbyClassroom}
                                        subProvidersArnMap={subProvidersArnMap}
                                    />
                                </Container>
                                <StudentRosterModal
                                    {...{
                                        openStudentListModal,
                                        closeStudentListModal: closeAndResetModal,
                                        providersMap,
                                        setProvidersMap,
                                        addStudentsToRoster,
                                        studentRosterErrors,
                                        studentRosterCapacityExceededErrors,
                                        setStudentRosterCapacityExceededErrors,
                                        classCapacitySizeExceededBy,
                                        setClassCapacitySizeExceededBy,
                                        roster: classData.classroom.studentRoster,
                                        capacityUpdateWithRoster,
                                        gilmoreLicenseError,
                                        setGilmoreLicenseError,
                                        showBtnSpinner,
                                        classroomId,
                                        addToRosterSucceeded,
                                        setAddToRosterSucceeded,
                                        resetErrorBanners,
                                        shouldResetStudentListInput,
                                        setShouldResetStudentListInput,
                                    }}
                                />
                                <RemoveStudentModal
                                    {...{
                                        showRemoveStudentModal,
                                        setShowRemoveStudentModal,
                                        removeStudentFromRoster,
                                        showBtnSpinner,
                                        email: studentToRemoveFromRoster,
                                        isRemovingAcceptedStudent,
                                    }}
                                />
                            </>
                        )}
                        <JamDetailsSection
                            jamTrainings={jamTrainings}
                            classroomRefetch={classroomRefetch}
                        />
                    </SpaceBetween>
                </ContentLayout>
            </Loader>
        </Layout>
    );
};

export const buildEmptyProvidersMap = (provider) => {
    return Object.fromEntries(
        provider.requiresSubProvider
            ? provider.subProviderData.map((data) => [
                  data.providerName,
                  {
                      arn: data.providerArn,
                      studentSubListValue: '',
                      capacity: 0,
                      studentCount: 0,
                  },
              ])
            : [
                  [
                      provider.name,
                      {
                          arn: provider.arn,
                          studentSubListValue: '',
                          capacity: 0,
                          studentCount: 0,
                      },
                  ],
              ],
    );
};

const loadInitialStudentListConfig = (classroomId, location, history, provider) => {
    const studentListModalConfig = getInitialConfigFromStudentListEditState(classroomId, location);

    if (studentListModalConfig) {
        history.replace({ ...location, state: undefined });
        return studentListModalConfig;
    } else {
        return {
            openStudentListModal: false,
            providersMap: buildEmptyProvidersMap(provider),
        };
    }
};

export default ClassDetailPage;
